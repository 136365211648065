import React, { useEffect } from "react";
import {
  BlogContainer,
  CenterAlign,
  Header,
  Body,
  BottomSpacer,
  Attribute
} from "./components";
import { Link } from "react-router-dom";
import { trackpage } from "./trackpage";

export function Policy() {
  return (
    <div>
      <CenterAlign>
        <BlogContainer>
          <Link to={"/privacy"}>
            <Header>Privacy Policy</Header>
          </Link>
          <Link to={"/termsofservice"}>
            <Header>Terms of Service</Header>
          </Link>
          <Link to={"/refunds"}>
            <Header>Cancellations and Refunds</Header>
          </Link>
        </BlogContainer>
        <BottomSpacer />
      </CenterAlign>
    </div>
  );
}

export function Terms() {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  return (
    <div>
      <CenterAlign>
        <BlogContainer>
          <Header>SliceGoal Terms of Service</Header>
          <Body>
            <Attribute>last updated 23 May 2021</Attribute>
            <Attribute>1. About the Website</Attribute>
            {
              "(a) Welcome to www.slicegoal.com (the 'Website'). The Website Coaching Web\
          Application (the 'Services'). \n\n(b) The Website is operated by Lateral\
          Products Pty Ltd (ABN 55 629 997 066). Access to and use of the\
          Website, or any of its associated Products or Services, is provided by\
          Lateral Products Pty Ltd. Please read these terms and conditions (the\
          'Terms') carefully. By using, browsing and/or reading the Website,\
          this signifies that you have read, understood and agree to be bound by\
          the Terms. If you do not agree with the Terms, you must cease usage of\
          the Website, or any of Lateral Products Services, immediately. \n\n(c) Lateral Products Pty\
          Ltd reserves the right to review and change any of the Terms by\
          updating this page at its sole discretion. When Lateral Products Pty\
          Ltd updates the Terms, it will use reasonable endeavours to provide\
          you with notice of updates to the Terms. Any changes to the Terms take\
          immediate effect from the date of their publication. Before you\
          continue, we recommend you keep a copy of the Terms for your records.\
          "
            }
          </Body>
          <Attribute>2. Acceptance of the Terms </Attribute>
          <Body>
            {
              "(a) You accept the Terms by remaining on\
          the Website. You may also accept the Terms by clicking to accept or\
          agree to the Terms where this option is made available to you by\
          Lateral Products Pty Ltd in the user interface."
            }
          </Body>
          <Attribute>3. Subscription to use the Services</Attribute>
          <Body>
            {
              "(a) In order to access the Services, you must first\
          purchase a subscription through the Website (the 'Subscription') and\
          pay the applicable fee for the selected Subscription (the\
            'Subscription Fee'). \n\n(b) In purchasing the Subscription, you\
          acknowledge and agree that it is your responsibility to ensure that\
          the Subscription you elect to purchase is suitable for your use. \n\n(c)\
          Once you have purchased the Subscription, you will then be required to\
          register for an account through the Website before you can access the\
          Services (the 'Account'). \n\n(d) As part of the registration process, or\
          as part of your continued use of the Services, you may be required to\
          provide personal information about yourself (such as identification or\
          contact details), including: \n\n(i) Email address \n(ii) Preferred username\
          \n(iii) Password \n\n(e) You warrant that any information you give to\
          Lateral Products Pty Ltd in the course of completing the registration\
          process will always be accurate, correct and up to date. \n\n(f) Once you\
          have completed the registration process, you will be a registered\
          member of the Website ('Member') and agree to be bound by the Terms.\
          As a Member you will be granted immediate access to the Services from\
          the time you have completed the registration process until the\
          subscription period expires (the 'Subscription Period'). \n\n(g) You may\
          not use the Services and may not accept the Terms if: \n(i) you are not\
          of legal age to form a binding contract with Lateral Products Pty Ltd;\
          or \n(ii) you are a person barred from receiving the Services under the\
          laws of Australia or other countries including the country in which\
          you are resident or from which you use the Services."
            }
          </Body>
          <Attribute>4. Your obligations as a Member</Attribute>
          <Body>
            {
              "(a) As a Member, you agree to comply with the\
          following: \n(i) you will use the Services only for purposes that are\
          permitted by: \n  (A) the Terms; and \n (B) any applicable law, regulation or\
          generally accepted practices or guidelines in the relevant\
          jurisdictions; \n(ii) you have the sole responsibility for protecting\
          the confidentiality of your password and/or email address. Use of your\
          password by any other person may result in the immediate cancellation\
          of the Services; \n(iii) any use of your registration information by any\
          other person, or third parties, is strictly prohibited. You agree to\
          immediately notify Lateral Products Pty Ltd of any unauthorised use of\
          your password or email address or any breach of security of which you\
          have become aware; \n(iv) access and use of the Website is limited,\
          non-transferable and allows for the sole use of the Website by you for\
          the purposes of Lateral Products Pty Ltd providing the Services; \n(v)\
          you will not use the Services or the Website in connection with any\
          commercial endeavours except those that are specifically endorsed or\
          approved by the management of Lateral Products Pty Ltd; \n(vi) you will\
          not use the Services or Website for any illegal and/or unauthorised\
          use which includes collecting email addresses of Members by electronic\
          or other means for the purpose of sending unsolicited email or\
          unauthorised framing of or linking to the Website; \n(vii) you agree\
          that commercial advertisements, affiliate links, and other forms of\
          solicitation may be removed from the Website without notice and may\
          result in termination of the Services. Appropriate legal action will\
          be taken by Lateral Products Pty Ltd for any illegal or unauthorised\
          use of the Website; and \n(viii) you acknowledge and agree that any\
          automated use of the Website or its Services is prohibited."
            }
          </Body>
          <Attribute>5. Payment</Attribute>
          <Body>
            {
              "(a) Where the option is given to you, you may make payment of the\
          Subscription Fee by way of: (i) Credit Card Payment ('Credit Card')\
          (b) All payments made in the course of your use of the Services are\
          made using eWAY. In using the Website, the Services or when making any\
          payment in relation to your use of the Services, you warrant that you\
          have read, understood and agree to be bound by the eWAY terms and\
          conditions which are available on their website. (c) You acknowledge\
          and agree that where a request for the payment of the Subscription Fee\
          is returned or denied, for whatever reason, by your financial\
          institution or is unpaid by you for any other reason, then you are\
          liable for any costs, including banking fees and charges, associated\
          with the Subscription Fee . (d) You agree and acknowledge that Lateral\
          Products Pty Ltd can vary the Subscription Fee at any time and that\
          the varied Subscription Fee will come into effect following the\
          conclusion of the existing Subscription Period. "
            }
          </Body>
          <Attribute>6. Cancellations</Attribute>
          <Body>
            {
              "(a) The Terms will continue to apply until terminated by either you or\
        by Lateral Products Pty Ltd as set out below. \n(b) If you want to\
        terminate the Terms, you may do so by: \n\nYour notice should be sent, in\
        writing, to Lateral Products Pty Ltd via subscriptions@slicegoal.com: \n(i) providing Lateral Products Pty Ltd with 14\
        days' notice of your intention to terminate; and \n(ii) closing\
        your accounts for all of the services which you use, where Lateral\
        Products Pty Ltd has made this option available to you. \n\n(c) Lateral\
        Products Pty Ltd may at any time, terminate the Terms with you if:\
        (i) you have breached any provision of the Terms or intend to\
        breach any provision; (ii) Lateral Products Pty Ltd is required to do\
        so by law; (iii) the provision of the Services to you by Lateral\
        Products Pty Ltd is, in the opinion of Lateral Products Pty Ltd, no\
        longer commercially viable. \n\n(d) Subject to local applicable laws,\
        Lateral Products Pty Ltd reserves the right to discontinue or cancel\
        your membership at any time and may suspend or deny, in its sole\
        discretion, your access to all or any portion of the Website or the\
        Services without notice if you breach any provision of the Terms or\
        any applicable law or if your conduct impacts Lateral Products Pty\
        Ltd's name or reputation or violates the rights of those of another\
        party. \n\n (e) If you would like to cancel your subscription:\n (i) you can do so in the account settings or \n (ii) please \
        contact subscriptions@slicegoal.com.\n\n (f) The subscription will be \
        inactivated at the end of the current billing cycle. We do not offer \
        credit for pro-rata days before the end of the current billing cycle. \
        This will prevent any future payments of subscription activations from \
        being processed automatically (unless you re-subscribe). \n\n(g) Your account \
        and information will remain accessible with the features available \
        with free access. At the end of your current billing cycle the \
        cancellation will take effect and paid features only available on \
        subscription will become unavailable. \n\n(h) If you would like the account \
        data to be removed, please send a request to \
        subscriptions@slicegoal.com. Account data will be deleted 14 days after the \
        request is received. Account data cannot be recovered after it's \
        deleted."
            }
          </Body>
          <Attribute>7. Refund Policy</Attribute>
          <Body>
            {
              "(a) Lateral Products Pty Ltd will only provide you with a refund of the\
          Subscription Fee in the event they are unable to continue to provide\
          the Services or if the manager of Lateral Products Pty Ltd makes a\
          decision, at its absolute discretion, that it is reasonable to do so\
          under the circumstances. Where this occurs, the refund will be in the\
          proportional amount of the Subscription Fee that remains unused by the\
          Member (the 'Refund'). \n(b) You can receive a refund within the first 30 days of payment for your subscription. \n\
          (c) For annual subscriptions, you also have 30 days to claim \
          a refund. We cannot offer refunds for requests beyond 30 days of the \
          payment date."
            }
          </Body>
          <Attribute>8. Copyright and Intellectual Property</Attribute>
          <Body>
            {
              "(a) The Website, the Services and all of the related products of Lateral\
          Products Pty Ltd are subject to copyright. The material on the Website\
          is protected by copyright under the laws of Australia and through\
          international treaties. Unless otherwise indicated, all rights\
          (including copyright) in the Services and compilation of the Website\
          (including but not limited to text, graphics, logos, button icons,\
          video images, audio clips, Website, code, scripts, design elements and\
          interactive features) or the Services are owned or controlled for\
          these purposes, and are reserved by Lateral Products Pty Ltd or its\
          contributors. (b) All trademarks, service marks and trade names are\
          owned, registered and/or licensed by Lateral Products Pty Ltd, who\
          grants to you a worldwide, nonexclusive, royalty-free, revocable\
          license whilst you are a Member to: Lateral Products Pty Ltd does not\
          grant you any other rights whatsoever in relation to the Website or\
          the Services. All other rights are expressly reserved by Lateral\
          Products Pty Ltd. (i) use the Website pursuant to the Terms; (ii) copy\
          and store the Website and the material contained in the Website in\
          your device's cache memory; and (iii) print pages from the Website for\
          your own personal and non-commercial use. (c) Lateral Products Pty Ltd\
          retains all rights, title and interest in and to the Website and all\
          related Services. Nothing you do on or in relation to the Website will\
          transfer any: to you. (i) business name, trading name, domain name,\
          trade mark, industrial design, patent, registered design or copyright,\
          or (ii) a right to use or exploit a business name, trading name,\
          domain name, trade mark or industrial design, or (iii) a thing, system\
          or process that is the subject of a patent, registered design or\
          copyright (or an adaptation or modification of such a thing, system or\
          process), (d) You may not, without the prior written permission of\
          Lateral Products Pty Ltd and the permission of any other relevant\
          rights owners: broadcast, republish, up-load to a third party,\
          transmit, post, distribute, show or play in public, adapt or change in\
          any way the Services or third party Services for any purpose, unless\
          otherwise provided by these Terms. This prohibition does not extend to\
          materials on the Website, which are freely available for re-use or are\
          in the public domain. "
            }
          </Body>
          <Attribute>9. Privacy</Attribute>
          <Body>
            {
              "(a) Lateral Products Pty Ltd takes\
          your privacy seriously and any information provided through your use\
          of the Website and/or Services are subject to Lateral Products Pty\
          Ltd's Privacy Policy, which is available on the Website."
            }
          </Body>
          <Attribute>10. General Disclaimer</Attribute>
          <Body>
            {
              '(a) Nothing in the Terms limits or excludes any guarantees,\
          warranties, representations or conditions implied or imposed by law,\
          including the Australian Consumer Law (or any liability under them)\
          which by law may not be limited or excluded. (b) Subject to this\
          clause, and to the extent permitted by law: (i) all terms, guarantees,\
          warranties, representations or conditions which are not expressly\
          stated in the Terms are excluded; and (ii) Lateral Products Pty Ltd\
          will not be liable for any special, indirect or consequential loss or\
          damage (unless such loss or damage is reasonably foreseeable resulting\
          from our failure to meet an applicable Consumer Guarantee), loss of\
          profit or opportunity, or damage to goodwill arising out of or in\
          connection with the Services or these Terms (including as a result of\
          not being able to use the Services or the late supply of the\
          Services), whether at common law, under contract, tort (including\
          negligence), in equity, pursuant to statute or otherwise. (c) Use of\
          the Website and the Services is at your own risk. Everything on the\
          Website and the Services is provided to you "as is" and "as available"\
          without warranty or condition of any kind. None of the affiliates,\
          directors, officers, employees, agents, contributors and licensors of\
          Lateral Products Pty Ltd make any express or implied representation or\
          warranty about the Services or any products or Services (including the\
          products or Services of Lateral Products Pty Ltd) referred to on the\
          Website, includes (but is not restricted to) loss or damage you might\
          suffer as a result of any of the following: (i) failure of\
          performance, error, omission, interruption, deletion, defect, failure\
          to correct defects, delay in operation or transmission, computer virus\
          or other harmful component, loss of data, communication line failure,\
          unlawful third party conduct, or theft, destruction, alteration or\
          unauthorised access to records; (ii) the accuracy, suitability or\
          currency of any information on the Website, the Services, or any of\
          its Services related products (including third party material and\
          advertisements on the Website); (iii) costs incurred as a result of\
          you using the Website, the Services or any of the products of Lateral\
          Products Pty Ltd; and (iv) the Services or operation in respect to\
          links which are provided for your convenience. '
            }
          </Body>
          <Attribute>11. Limitation of liability</Attribute>
          <Body>
            {
              "(a) Lateral Products Pty Ltd's total liability arising out\
          of or in connection with the Services or these Terms, however arising,\
          including under contract, tort (including negligence), in equity,\
          under statute or otherwise, will not exceed the resupply of the\
          Services to you. (b) You expressly understand and agree that Lateral\
          Products Pty Ltd, its affiliates, employees, agents, contributors and\
          licensors shall not be liable to you for any direct, indirect,\
          incidental, special consequential or exemplary damages which may be\
          incurred by you, however caused and under any theory of liability.\
          This shall include, but is not limited to, any loss of profit (whether\
          incurred directly or indirectly), any loss of goodwill or business\
          reputation and any other intangible loss."
            }
          </Body>
          <Attribute>12. Indemnity</Attribute>
          <Body>
            {
              "(a) You agree to indemnify Lateral Products Pty\
          Ltd, its affiliates, employees, agents, contributors, third party\
          content providers and licensors from and against: (i) all actions,\
          suits, claims, demands, liabilities, costs, expenses, loss and damage\
          (including legal fees on a full indemnity basis) incurred, suffered or\
          arising out of or in connection with Your Content; (ii) any direct or\
          indirect consequences of you accessing, using or transacting on the\
          Website or attempts to do so; and/or (iii) any breach of the Terms."
            }
          </Body>
          <Attribute>13. Dispute Resolution</Attribute>
          <Body>
            {
              "(a) If a dispute arises out of or\
          relates to the Terms, either party may not commence any Tribunal or\
          Court proceedings in relation to the dispute, unless the following\
          clauses have been complied with (except where urgent interlocutory\
          relief is sought). (b) Notice: A party to the Terms claiming a dispute\
          ('Dispute') has arisen under the Terms, must give written notice to\
          the other party detailing the nature of the dispute, the desired\
          outcome and the action required to settle the Dispute. (c) Resolution:\
          On receipt of that notice ('Notice') by that other party, the parties\
          to the Terms ('Parties') must: (i) Within 30 days days of the Notice\
          endeavour in good faith to resolve the Dispute expeditiously by\
          negotiation or such other means upon which they may mutually agree;\
          (ii) If for any reason whatsoever, 60 days days after the date of the\
          Notice, the Dispute has not been resolved, the Parties must either\
          agree upon selection of a mediator or request that an appropriate\
          mediator be appointed by the President of the The Dispute Settlement\
          Centre of Victoria or his or her nominee; (iii) The Parties are\
          equally liable for the fees and reasonable expenses of a mediator and\
          the cost of the venue of the mediation and without limiting the\
          foregoing undertake to pay any amounts requested by the mediator as a\
          pre-condition to the mediation commencing. The Parties must each pay\
          their own costs associated with the mediation; (iv) The mediation will\
          be held in Melbourne, Australia. (d) Confidential: All communications\
          concerning negotiations made by the Parties arising out of and in\
          connection with this dispute resolution clause are confidential and to\
          the extent possible, must be treated as \"without prejudice\"\
          negotiations for the purpose of applicable laws of evidence. (e)\
          Termination of Mediation: If 30 days have elapsed after the start of a\
          mediation of the Dispute and the Dispute has not been resolved, either\
          Party may ask the mediator to terminate the mediation and the mediator\
          must do so. "
            }
          </Body>
          <Attribute>14. Venue and Jurisdiction</Attribute>
          <Body>
            {
              "(a) The Services offered by\
          Lateral Products Pty Ltd is intended to be viewed by residents of\
          Australia. In the event of any dispute arising out of or in relation\
          to the Website, you agree that the exclusive venue for resolving any\
          dispute shall be in the courts of Victoria, Australia."
            }
          </Body>
          <Attribute>15. Governing Law</Attribute>
          <Body>
            {
              "(a) The Terms are governed by the laws of Victoria, Australia. Any\
          dispute, controversy, proceeding or claim of whatever nature arising\
          out of or in any way relating to the Terms and the rights created\
          hereby shall be governed, interpreted and construed by, under and\
          pursuant to the laws of Victoria, Australia, without reference to\
          conflict of law principles, notwithstanding mandatory rules. The\
          validity of this governing law clause is not contested. The Terms\
          shall be binding to the benefit of the parties hereto and their\
          successors and assigns. "
            }
          </Body>
          <Attribute>16. Independent Legal Advice</Attribute>
          <Body>
            {
              "(a) Both parties\
          confirm and declare that the provisions of the Terms are fair and\
          reasonable and both parties having taken the opportunity to obtain\
          independent legal advice and declare the Terms are not against public\
          policy on the grounds of inequality or bargaining power or general\
          grounds of restraint of trade. "
            }
          </Body>
          <Attribute>17. Severance</Attribute>
          <Body>
            {
              "(a) If any part of these\
          Terms is found to be void or unenforceable by a Court of competent\
          jurisdiction, that part shall be severed and the rest of the Terms\
          shall remain in force."
            }
          </Body>
        </BlogContainer>
      </CenterAlign>
      <BottomSpacer />
    </div>
  );
}

export function Refunds() {
  return (
    <div>
      <CenterAlign>
        <BlogContainer>
          <Header>
            SliceGoal Cancellations and Refunds
          </Header>
          <Body>
            {
              "You can receive a refund within the first 30 days of payment for your subscription. \
              For annual subscriptions, you also have 30 days to claim \
              a refund. We cannot offer refunds for requests beyond 30 days of the \
              payment date. \n\n If you would like to cancel your subscription, please \
              contact subscriptions@slicegoal.com.\n\n The subscription will be \
              inactivated at the end of the current billing cycle. We do not offer \
              credit for pro-rata days before the end of the current billing cycle. \
              This will prevent any future payments of subscription activations from \
              being processed automatically (unless you re-subscribe). Your account \
              and information will remain accessible with the features available \
              with free access. At the end of your current billing cycle the \
              cancellation will take effect and paid features only available on \
              subscription will become unavailable. \n\nIf you would like the account \
              data to be removed, please send a request to \
              subscriptions@slicegoal.com. Account data will be deleted 14 days after the \
              request is received. Account data cannot be recovered after it's \
              deleted."
            }
          </Body>
        </BlogContainer>
      </CenterAlign>
      <BottomSpacer />
    </div>
  );
}

export function Privacy() {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  return (
    <div>
      <CenterAlign>
        <BlogContainer>
          <Header>SliceGoal Privacy Policy</Header>
          <Attribute>last updated 10 Oct 2024</Attribute>
          <Attribute>1. We respect your privacy</Attribute>
          <Body>
            {
              '(a) Lateral Products Services (SliceGoal) respects your right \
          to privacy and is committed to safeguarding the privacy of our \
          customers and website visitors. We adhere to the Australian Privacy \
          Principles contained in the Privacy Act 1988 (Cth). This policy sets \
          out how we collect and treat your personal information. (b) "Personal \
          information" is information we hold which is identifiable as being\
          about you. '
            }
          </Body>
          <Attribute>2. Collection of personal information</Attribute>
          <Body>
            {
              "(a) Lateral Products\
          Services (SliceGoal) will, from time to time, receive and store\
          personal information you enter onto our website, provided to us\
          directly or given to us in other forms. (b) You may provide basic\
          information such as your name, phone number, address and email address\
          to enable us to send information, provide updates and process your\
          product or service order. We may collect additional information at\
          other times, including but not limited to, when you provide feedback,\
          when you provide information about your personal or business affairs,\
          change your content or email preference, respond to surveys and/or\
          promotions, provide financial or credit card information, or\
          communicate with our customer support. (c) Additionally, we may also\
          collect any other information you provide while interacting with us.\
          "
            }
          </Body>
          <Attribute>3. How we collect your personal information</Attribute>
          <Body>
            {
              "(a) Lateral Products\
          Services (SliceGoal) collects personal information from you in a\
          variety of ways, including when you interact with us electronically or\
          in person, when you access our website and when we provide our\
          services to you. We may receive personal information from third\
          parties. We may receive information from you from social media. If we\
          do, we will protect it as set out in this Privacy Policy. "
            }
          </Body>
          <Attribute>4. Use of your personal information</Attribute>
          <Body>
            {
              "(a) Lateral Products Services (SliceGoal) may\
          use personal information collected from you to provide you with\
          information, updates and our services. We may also make you aware of\
          new and additional products, services and opportunities available to\
          you. We may use your personal information to improve our products and\
          services and better understand your needs. (b) Lateral Products\
          Services (SliceGoal) may contact you by a variety of measures\
          including, but not limited to telephone, email, sms or mail. "
            }
          </Body>
          <Attribute>5. Disclosure of your personal information</Attribute>
          <Body>
            {
              "(a) We may disclose your\
          personal information to any of our employees, officers, insurers,\
          professional advisers, agents, suppliers or subcontractors insofar as\
          reasonably necessary for the purposes set out in this Policy. Personal\
          information is only supplied to a third party when it is required for\
          the delivery of our services. (b) We may from time to time need to\
          disclose personal information to comply with a legal requirement, such\
          as a law, regulation, court order, subpoena, warrant, in the course of\
          a legal proceeding or in response to a law enforcement agency request.\
          (c) We may also use your personal information to protect the\
          copyright, trademarks, legal rights, property or safety of Lateral\
          Products Services (SliceGoal), www.slicegoal.com, its customers or third\
          parties. (d) Information that we collect may from time to time be\
          stored, processed in or transferred between parties located in\
          countries outside of Australia. (e) If there is a change of control in\
          our business or a sale or transfer of business assets, we reserve the\
          right to transfer to the extent permissible at law our user databases,\
          together with any personal information and non-personal information\
          contained in those databases. This information may be disclosed to a\
          potential purchaser under an agreement to maintain confidentiality. We\
          would seek to only disclose information in good faith and where\
          required by any of the above circumstances. (f) By providing us with\
          personal information, you consent to the terms of this Privacy Policy\
          and the types of disclosure covered by this Policy. Where we disclose\
          your personal information to third parties, we will request that the\
          third party follow this Policy regarding handling your personal\
          information. "
            }
          </Body>
          <Attribute>6. Security of your personal information</Attribute>
          <Body>
            {
              "(a) Lateral\
          Products Services (SliceGoal) is committed to ensuring that the\
          information you provide to us is secure. In order to prevent\
          unauthorised access or disclosure, we have put in place suitable\
          physical, electronic and managerial procedures to safeguard and secure\
          information and protect it from misuse, interference, loss and\
          unauthorised access, modification and disclosure. (b) The transmission\
          and exchange of information is carried out at your own risk. We cannot\
          guarantee the security of any information that you transmit to us, or\
          receive from us. Although we take measures to safeguard against\
          unauthorised disclosures of information, we cannot assure you that\
          personal information that we collect will not be disclosed in a manner\
          that is inconsistent with this Privacy Policy. "
            }
          </Body>
          <Attribute>7. Access to your personal information </Attribute>
          <Body>
            {
              "(a) You may request details of personal\
          information that we hold about you in accordance with the provisions\
          of the Privacy Act 1988 (Cth). A small administrative fee may be\
          payable for the provision of information. If you would like a copy of\
          the information, which we hold about you or believe that any\
          information we hold on you is inaccurate, out of date, incomplete,\
          irrelevant or misleading, please email us at\
          privacy@slicegoal.com. (b) We reserve the right to refuse to\
          provide you with information that we hold about you, in certain\
          circumstances set out in the Privacy Act. "
            }
          </Body>
          <Attribute>8. Complaints about privacy</Attribute>
          <Body>
            {
              "(a) If you have any complaints about our privacy practices, please\
          feel free to send in details of your complaints to\
          privacy@slicegoal.com, or Lateral Products, 1-3 Albert St,\
          Blackburn, Victoria, 3130. We take complaints very seriously and will\
          respond shortly after receiving written notice of your complaint. "
            }
          </Body>
          <Attribute>9.Changes to Privacy Policy</Attribute>
          <Body>
            {
              "(a) Please be aware that we may change this\
          Privacy Policy in the future. We may modify this Policy at any time,\
          at our sole discretion and all modifications will be effective\
          immediately upon our posting of the modifications on our website or\
          notice board. Please check back from time to time to review our\
          Privacy Policy. "
            }
          </Body>
          <Attribute>10. Website</Attribute>
          <Body>
            {
              "(a) When you visit our website, www.slicegoal.com, we may collect certain\
          information such as browser type, operating system, website visited\
          immediately before coming to our site, etc. This information is used\
          in an aggregated manner to analyse how people use our site, such that\
          we can improve our service. (b) Cookies We may from time to time use\
          cookies on our website. Cookies are very small files which a website\
          uses to identify you when you come back to the site and to store\
          details about your use of the site. Cookies are not malicious programs\
          that access or damage your computer. Most web browsers automatically\
          accept cookies but you can choose to reject cookies by changing your\
          browser settings. However, this may prevent you from taking full\
          advantage of our website. Our website may from time to time use\
          cookies to analyses website traffic and help us provide a better\
          website visitor experience. In addition, cookies may be used to serve\
          relevant ads to website visitors through third-party services such as\
          Google Adwords. These ads may appear on this website or other websites\
          you visit. (c) Third-party sites Our site may from time to time have\
          links to other websites not owned or controlled by us. These links are\
          meant for your convenience only. Links to third-party websites do not\
          constitute sponsorship or endorsement or approval of these websites.\
          Please be aware that Lateral Products Services (SliceGoal) is not\
          responsible for the privacy practices of other such websites. We\
          encourage our users to be aware, when they leave our website, to read\
          the privacy statements of each and every website that collects\
          personally identifiable information."
            }
          </Body>
        </BlogContainer>
      </CenterAlign>
      <BottomSpacer />
    </div>
  );
}

export default Privacy;
