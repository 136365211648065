import styled from "styled-components";
import React from "react";

import logo from "../src/images/slicegoal.png";
import logolong from "../src/images/logo.blackbg.horiz.png";
import productshot from "../src/images/productshot.svg";
//import team from "../src/images/team.jpeg";
import user from "../src/images/awesomeuser.jpg";
import habitbuilder from "../src/images/habit-builder-hero.png";
import profilepic from "../src/images/profilepic.png";
import lightning from "../src/images/lightning.svg";
import click from "../src/images/click.svg";
import wheel from "../src/images/wheel.svg";
import tick from "../src/images/tick.svg";
import hamburger from "../src/images/hamburger.png";

import quote from "../src/images/quote.svg";
import instagram from "../src/images/instagram.svg";
import tickboxes from "../src/images/tickboxes.svg";
import explorer from "../src/images/explorer.svg";
import compass from "../src/images/compass.svg";
import wreath from "../src/images/wreath.svg";
import bw_insta from "../src/images/B&W_Insta.png";

import { Link } from "react-router-dom";

const globalstyles = {
  radius: "7px",
  outline: "none"
};

export const SVGImage = props => {
  switch (props.icon) {
      case 'quote':
          return quote
      case 'instagram':
          return instagram
      case 'bw_insta':
          return bw_insta
      case 'lightning':
          return lightning
      case 'tickboxes':
          return tickboxes
      case 'explorer':
          return explorer
      case 'compass':
          return compass
      case 'wreath':
          return wreath
      default:
          return null
  }
}

export const SVGIcon = props => {
  var icon = SVGImage(props)
  if (icon)
      return (
          <img
              style={{
                  cursor: props.cursor ? props.cursor : 'pointer',
                  marginRight: props.margin,
                  //marginLeft: '2px',
                  //marginRight: '4px',
                  marginTop: props.marginTop,
                  filter: props.blue ? 'invert(69%) sepia(57%) saturate(2115%) hue-rotate(159deg) brightness(101%) contrast(102%)' : null
              }}
              height={props.height}
              src={icon}
              alt={icon}
              title={props.tooltip}
              onClick={props.onClick}
              data-cy={props.data_cy}
          />
      )
  else {
      return <span>no image</span>
  }
}

/* const typography = {
  heading: "'Karla'",
  subheading: "'Karla'",
  body: "Karla",
  sizeheading: "1.2em", //Bases: 1em, ratio: 1.2 https://www.modularscale.com/
  sizesubheading: "1.0em",
  sizebody: "0.9em",
  sizemini: "0.694em",
  lineheight: "1.5em",
  mobile: {
    sizeheading: "1.2em",
    sizesubheading: "2.0em",
    sizebody: "1.5em",
    sizemini: "0.694em",
  },
  weight: { normal: 500, bold: 700 },
} */

export const Intro = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  @media only screen and (max-width: 600px) {
    display: inline;
  }
`;

export const CVP = styled.div`
  font-size: 1.2em;
  @media only screen and (max-width: 600px) {
    font-size: 0.8em;
    margin: 0 20px;
  }
`;

const DemoImg = styled.img`
  max-width: 400px;
  max-height: 387.7px;
  width: ${props => (props.width ? props.width : "80%;")};
  margin-top: 30px;
  @media only screen and (max-width: 900px) {
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    padding-right: 0px;
  }
`;

export const colors = {
  primary: '#00798c',
  secondary: '#edf9ff',
  success: '#9EBC9E',
  warning: '#edae49',
  danger: '#d1495b',
  info: '#7180AC',
  green: {
      light: '#E1EFBA',
      bright: '#44ad13'
  },
  brand: {
      green: '#D9FF00',
      blue: '#27D0FC',
      darkblue: '#172543'
  },
  white: '#FFFFFF',
  grey: { light: '#BFBFBF', dark: '#686868' },
  rank: { low: '#072F5F', middle: '#1261A0', high: '#3895D3' },
  dark: '#414144'
}


/* const LogoIcon = styled.img`
  position: sticky;
  top: 15px;
  margin: auto;
  height: 25px;
  @media only screen and (max-width: 1000px) {
    left: 0;
  }
`; */

/* const DemoImg2 = styled.img`
  width: 350px;
  margin-left: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    padding: 0 15px;
  }
`; */

export const Section = styled.div`
  max-width: 400px;
  text-align: left;
  margin: 20px;
  @media (max-width: 900px) {
    text-align: center;
    margin: 30px auto;
  }
`;

export const Click = () => {
  return (
    <img
      style={{ height: "60px" }}
      id="click"
      alt="click"
      src={click}
    />
  );
};

export const WheelImage = () => {
  return (
    <img
      style={{ height: "120px", margin: '0 auto' }}
      id="demo"
      alt="demo"
      src={wheel}
    />
  );
};

export const ProductShot = () => {
  return (
    <DemoImg
      id="demo"
      alt="demo image"
      src={productshot}
    />
  );
};

export const Image = ({image, id, alt, width}) => {
  return (
    <DemoImg
      id={id}
      alt={alt}
      src={image}
      width={width}
    />
  );
};

const HeaderIcon = styled.img`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  height: 22px;
  @media only sceen and (max-width: 600px) {
    margin-top: 25px;
  }
`;

export const Carousel = styled.div`
  display: grid;
  width: 100vw;
  grid-template-columns: 1fr 900px 1fr; 
  grid-template-areas:
    ".    tiles   .";

  @media only screen and (max-width: 900px) {
    grid-template-columns: 100px auto 100px; 
  }

  @media only screen and (max-width: 600px) {
    width: 100vw;
    grid-template-columns: 20px auto 20px; 
  }
`;

export const HeaderLogo = () => {
  return (
    <Link to={"/"}>
      <HeaderIcon id="logo" alt="slicegoal" src={logolong} />
    </Link>
  );
};

export const Feature = props => {
  return (
    <FeatureStyle>
      <FeatureIcon id="logo" alt="slicegoal" src={tick} />
      <FeatureTitle>{props.feature}</FeatureTitle>
    </FeatureStyle>
  );
};

export const MenuBar = styled.div`
  margin-top: 20px;
  margin-left: auto;
  display: flex;
  font-size: 13px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Login = styled.div`
  margin-left: 15px;
  float: left;
  color: #000;
  text-decoration: none;
  font-family: Karla;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 600px) {
    margin-left: auto;
  }
`

export const Card = styled.div`
  border-radius: 15px; 
  padding: 10px; 
  text-align: left; 
  margin: 0 30px 20px 30px; 
  display: flex; 
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  font-family: sans-serif;
  @media (max-width: 900px) {
    text-align: center; 
    margin: 0 auto; 
    max-width: 320px;
  }
`

export const Tiles = styled.div`
  grid-area: tiles; 
  display: flex;
  @media (max-width: 900px) {
    display: block;
  }
`

export const MobileMenu = ({ menuClick }) => {
  return (
    <StyleMobileMenu
      onClick={e => menuClick(e)}
      id="logo"
      alt="menu"
      src={hamburger}
    />
  );
};

const StyleMobileMenu = styled.img`
  cursor: pointer;
  height: 15px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const MenuCTA = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    margin-left: auto;
  }
`;

const FeatureStyle = styled.div`
  display: flex;
  align-items: left;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  @media only screen and (max-width: 600px) {
    margin-left: auto;
  }
`;

const FeatureIcon = styled.img`
  height: 15px;
  margin-top: 8px;
`;

const FeatureTitle = styled.div`
  font-family: Karla;
  font-weight: 200;
  margin-left: 5px;
  margin-top: 7px;
  text-align: left;
  font-size: 18px;
`;

export const Quote = styled.div`
  font-size: 0.8em;
  text-align: left;
  margin-left: -50px;
  margin-right: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 0.6em;
    margin-left: -20px;
  }
`;

const Title = styled.div`
  font-family: Futura, Georgia, Cambria, "Times New Roman", Times, serif;
  text-align: left;
  font-size: 18px;
`;

const Text = styled.div`
  text-align: center;
  font-size: 0.6em;
`;

export const AppGrid = styled.div`
  font-family: sans-serif;
  min-height: 100%;
  position: relative;
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 2fr 3fr 2fr;
  grid-template-rows: 70px auto;
  grid-template-areas:
    "header    header    header"
    ".    content   .";

  @media only screen and (max-width: 1068px) {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas:
    "header    header    header"
    "content   content  content";
  }

  @media only screen and (max-width: 734px) {
    grid-template-columns: 15px auto 15px;
    grid-template-areas:
    "header    header    header"
    "content    content   content";
  }
`;

export const CVPTitle = styled.div`
  margin: 20px;
  text-align: left;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const LeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 450px 450px 1fr;
  grid-template-areas: ". left right .";
  @media only screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 100vw;
    grid-template-areas:
      "left"
      "right";
  }
`;

export const RightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 450px 450px 1fr;
  grid-template-areas: ". left right .";
  @media only screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 100vw;
    grid-template-areas:
      "right"
      "left";
  }
`;

export const GutterResponsive = styled.div`
  display: grid;
  grid-template-columns: 1fr 900px 1fr;
  grid-template-areas: ". content   .";
  @media only screen and (max-width: 900px) {
    width: 100vw;
    text-align: center;
    grid-template-columns: 20px auto 20px;
  }
`;

export const GridCVP = styled.div`
  margin: 50px 0;
  display: grid;
  grid-template-columns: 1fr 450px 450px 1fr;
  grid-template-areas: "teamleftfill leftcvp rightcvp teamright";

  @media only screen and (max-width: 900px) {
    text-align: center;
    grid-template-columns: 100vw;
    grid-template-areas:
      "leftcvp"
      "rightcvp";
  }
`;


export const TeamImage = () => {
  return <TeamImg id="demo" alt="demo image" src={user} />;
};

export const HabitBuilderHeroImage = () => {
  return <HabitHeroImg id="demo" alt="demo image" src={habitbuilder} />;
};

export const ProfilePicImage = () => {
  return <ProfilePicImg id="demo" alt="demo image" src={profilepic} />;
};

export const HabitHeroImg = styled.img`
  grid-area: teamimage;
  margin-left: 20px;
  max-height: 400px;
  max-width: 400px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 734px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ProfilePicImg = styled.img`
  grid-area: teamimage;
  margin-left: 20px;
  max-height: 400px;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 734px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const TeamImg = styled.img`
  grid-area: teamimage;
  border: solid 0.5px rgba(128, 128, 128, 0.35);
  border-radius: 10px;
  margin-left: 20px;
  max-height: 400px;
  max-width: 400px;
  @media only screen and (max-width: 734px) {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const TeamLeft = styled.div`
  grid-area: teamleft;
  background: linear-gradient(
    #ebd2b5 10%,
    #e7ccaf 30%,
    #e1c4a2 70%,
    rgb(221 191 155) 100%
  );
  float: left;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 734px) {
    background: none;
  }
`;

export const TeamLeftFill = styled.div`
  grid-area: teamleftfill;
  background: linear-gradient(
    #ebd2b5 10%,
    #e7ccaf 30%,
    #e1c4a2 70%,
    rgb(221 191 155) 100%
  );
  @media only screen and (max-width: 734px) {
    background: none;
  }
`;
export const TeamRight = styled.div`
  grid-area: teamright;
`;

export const Content = styled.div`
  grid-area: content;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 80px;
  grid-template-columns: 100vw;
  grid-template-areas: 
    "page" 
    "footer";
  
  @media only screen and (max-width: 734px) {
    overflow-x: scroll;
  }
`;

export const Benefit = props => {
  return (
    <BenefitStyle>
      <FeatureIcon id="logo" alt="slicegoal" src={logo} />
      <Title>{props.title}</Title>
      <Text>{props.text} </Text>
    </BenefitStyle>
  );
};

export const MenuLink = props => {
  return (
    <Link
      style={{
        marginLeft: "15px",
        color: "#FFF",
        textDecoration: "none",
        fontWeight: 700
      }}
      to={props.link}
    >
      <MenuLinkText>{props.text}</MenuLinkText>
    </Link>
  );
};

export const BenefitBox = styled.div`
  margin: auto;
  width: 100%;
  height: 100px;
`;

const BenefitStyle = styled.div`
  text-align: center;
  margin: 20px;
  width: 250px;
`;

export const CenterAlign = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const BottomCTA = styled.div`
  margin: 0px auto 70px auto;
  @media only screen and (max-width: 600px) {
    margin-top: 100px;
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  float: ${props => (props.float ? props.float : "right")};
  font-size: ${props => {
    switch(props.type){
      case('tertiary'): return '16px'
      case('secondary'):
      default: return '14px'
  }}};
  cursor: pointer;
  margin: ${props => (props.margin ? props.margin + "px" : "1px")};
  color: ${props => props.type === 'secondary' || props.type === 'tertiary' ? 'grey': '#383838'};
  background: ${props => props.type === 'secondary' || props.type === 'tertiary' ? colors.white : '#d9ff00'};
  border: ${props => {
    switch(props.type){
      case('secondary'): return 'solid 0.5px rgba(128, 128, 128, 0.35)'
      case('tertiary'): 
      default: return 'none'
  }}};
  outline: ${globalstyles.outline};
  font-family: sans-serif;
  height: 30px;
  &:hover {
    text-decoration: underline;
  }
`;
export const SquareButton = styled.button`
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  float: ${props => (props.float ? props.float : "right")};
  font-size: 14px;
  cursor: pointer;
  margin: ${props => (props.margin ? props.margin + "px" : "1px")};
  background: ${props => (props.light ? "white" : colors.brand.blue)};
  outline: ${globalstyles.outline};
  font-family: sans-serif;
  font-weight: 700;
  border: ${props =>
    props.light ? "solid 1px rgba(128, 128, 128, 0.35)" : "none"};
  color: ${props => (props.light ? colors.dark : "white")} !important;
  height: 30px;
  &:hover {
    text-decoration: underline;
  }
`;

export const LeftButton = styled.div`
  margin-top: 20px; 
  display: flex; 
  justify-content: left;
  @media only screen and (max-width: 900px) {
    justify-content: center;
    margin: 30px;
  }
`;

export const HeaderBar = styled.div`
  height: 55px;
  position: sticky;
  top: 0px;
  background: white;
  grid-area: header;
  width: 100%;
  border-bottom: solid 0.2px rgba(128, 128, 128, 0.35);
  background: ${colors.brand.darkblue};
  z-index: 100;
`;

export const Footer = styled.div`
  grid-area: footer;
  text-align: left;
  font-size: 12px;
  background: #f9f9f9;
  marginTop: auto;
  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const MenuLinkText = styled.div`
  text-decoration: "none";
  font-family: "Open Sans";
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

export const BodyDiv = styled.div`
  overflow-x: scroll;
  font-family: sans-serif;
`;

export const BlogBodyDiv = styled.div`
  font-family: sans-serif;
  margin: 64px;
  max-width: 680px;
`;

export const FeatureList = styled.div`
  font-size: 0.8em;
`;

export const FeatureHeading = styled.div`
  font-size: 0.9em;
  font-weight: 800;
`;

export const Features = styled.div`
  margin: 10px;
  @media only screen and (max-width: 600px) {
    margin-left: 20px;
  }
`;

export const PointTitle = styled.div`
  margin-top: 50px;
  font-family: "Source Sans Pro", Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 32px;
  text-align: ${props => (props.align ? props.align : 'left')};
  font-weight: 700;
  white-space: pre-line;
  line-height: ${props => (props.lineheight ? props.lineheight :'')};
  @media only screen and (max-width: 900px) {
    font-size: 30px;
    text-align: ${props => (props.align ? props.align : 'center')};
    line-height: 32px;
  }
`;

export const CarouseCard = styled.div`
  font-family: sans-serif;
  font-size: 20px; 
  color: #414144;
  @media only screen and (max-width: 600px) {
    font-family: sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const CVPSection = styled.div`
  margin: 100px 0;
  width: 100%;
  @media only screen and (max-width: 734px) {
    float: none;
    margin-bottom: 150px;
  }
`;

export const Header = styled.div`
  font-family: Open Sans, Futura, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
`;

export const Body = styled.div`
  font-family: Open Sans, Karla;
  font-size: 16px;
  margin-bottom: 20px;
  white-space: pre-line;
  line-height: 26px;
`;

export const BodyBold = styled.div`
  font-family: Times, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 20px;
`;

export const Attribute = styled.div`
  font-family: Futura, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 15px; 
  text-align: ${props => (props.align ? props.align : 'left')};
  color: ${colors.brand.blue};
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const BottomSpacer = styled.div`
  margin-top: 120px;
  width: 100%;
  color: white;
  clear: both;
`;

export const BlogContainer = styled.div`
  max-width: 600px;
  margin-top: 60px;
  margin-left: 15px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    margin: 0 20px;
  }
`;

export function PromotionCard({name, text, icon}) {
  return (
    <Card>
      {icon && <div style={{height:'100px', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <SVGIcon icon={icon} height={60}/>
      </div>}
      <CarouseCard>{name}</CarouseCard>
      <div style={{fontSize: "18px", color: "#9499A3", marginTop:'10px'}}>{text}</div>
    </Card>
  );
}

export function QuoteSection({quote,attribution}) {
return (
  <div style={{maxWidth: '900px', margin: '60px auto 0 auto', padding: '0 20px'}}>
  <SVGIcon icon={'quote'} height={'20'}/>
  <div style={{ fontSize: "18px", margin: "10px 10px", color: "#9499A3" }}>
    {quote}
  </div>
  <div style={{ fontSize: "16px", color: "#9499A3", textAlign: 'right', marginRight: '20px' }}>
      {'- ' + attribution}
    </div>
  </div>
)
}