import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { Toaster } from 'react-hot-toast'
import {
  Button,
  HeaderLogo,
  HeaderBar,
  AppGrid,
  Content,
  Footer,
  MenuBar,
  MenuLink,
  MobileMenu,
  Login,
  SVGIcon
} from "../src/components";
import ScrollToTop from "../src/Scroll.js";
//import Teams from "./teams";
import Blog from "../src/Blog.Landing";
import Unsubscribe from "../src/Unsubscribe";
import About from "../src/About";
import Home from "../src/Home";
import StartHere from "../src/StartHere";
import OfferHabits from "./Offer.Habits.Optin"
import OfferHabitsSuccess from "./Offer.Habits.Optin.Success"
import SliceGoalHabitsOffer from "./Offer.Habits.Optin.SliceGoal"

import { Policy, Privacy, Terms, Refunds } from "../src/Policy";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { trackpage } from "./trackpage";
import How from "./How.js";

const tagManagerArgs = {
  gtmId: "GTM-W4DSF7G"
};

TagManager.initialize(tagManagerArgs);

const menuoptions = [
  { name: "Home", link: "/" },
  { name: "How Does it Work?", link: "/how-it-works/" },
  { name: "Your Choice", link: "/start-here/" },
  { name: "About", link: "/about/" }
];

function App() {
  const [menu, openmenu] = useState(false)
  const [menuanchor, setanchor] = useState(null)
  const [tagfilter, setFilter] = useState('')

  window.onbeforeunload = function() {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      action: "closed"
    });
  };

  return (
    <Router>
      <ScrollToTop>
        <Toaster/>
        <AppGrid>
              {/* <SEO title="SliceGoal" description="Goals and Growth Pillars" /> */}
              <HeaderBar>
                <div
                  style={{ maxWidth: "900px", margin: "0 auto", display: "flex" }}
                >
                  <HeaderLogo />
                  <MenuBar>
                    {menuoptions.map(item => <MenuLink text={item.name} link={item.link} />)}
                  </MenuBar>
                  <Login>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.slicegoal.com/app/"
                    >
                      <Button style={{
                        marginRight: '20px',
                        fontSize: '12px',
                        fontWeight: '700'
                      }} margin={12}>Log in</Button>
                    </a>
                  </Login>
                  
                  <MobileMenu menuClick={e => menuClick(e)} />
                  <Menu
                    id="long-menu"
                    anchorEl={menuanchor}
                    open={menu}
                    onClose={() => openmenu(false)}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 6,
                        width: 200
                      }
                    }}
                  >
                    {menuoptions.map(option => (
                      <a
                        style={{ color: "black", textDecoration: "none" }}
                        href={option.link}
                      >
                        <MenuItem key={option.name}>{option.name}</MenuItem>
                      </a>
                    ))}
                  </Menu>
                </div>
              </HeaderBar>
              {/* A <Routes> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
              <Content>
                <Routes>
                  <Route exact path="/" element={<Home/>}/> {/* this needs to be last because it matches with all paths*/}
                  <Route path="how-it-works" element={<How setFilter={filter => setFilter(filter)}/>}/>
                  <Route path="about" element={<About/>}/>
                  <Route path="start-here" element={<StartHere tagfilter={tagfilter} />}/>
                  
                  <Route path="policy" element={<Policy/>}/>
                  <Route path="termsofservice" element={<Terms/>}/>
                  <Route path="privacy" element={<Privacy/>}/>
                  <Route path="refunds" element={<Refunds/>}/>
                  
                  <Route path="offer/building-habits" element={<OfferHabits />}/>
                  <Route path="offer/building-habits/success" element={<OfferHabitsSuccess />}/>
                  <Route path="offer/building-habits/slicegoal" element={<SliceGoalHabitsOffer />}/>
                  <Route path="unsubscribe" element={<Unsubscribe />}/>
                  
                  <Route exact path="blog" element={<Blog/>}/>

                  <Route path="learn" element={<Navigate to="/404" />} />

                  <Route path="/*" element={<Navigate replace to="/" />} />
                  <Route path="/404" element={<NotFoundPage/>}/>
                </Routes>
                <Footer>
                  <div
                    style={{
                      //width: "100%",
                      maxWidth: "860px",
                      margin: "0 auto",
                      padding: "0 20px",
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    {/* <MenuLink text={"Privacy"} link={"/privacy"} />
                    <MenuLink text={"Terms of Service"} link={"/termsofservice"} /> */}
                    <div
                      style={{
                        width: '150px',
                        fontFamily: 'Futura, Georgia, Cambria, "Times New Roman"',
                        color: '#9499A3',
                        margin: 'auto 0'
                      }}
                    >
                      © SliceGoal 2024
                    </div>
                    
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/slicegoal_/"
                      style={{ marginTop: '10px' }}
                    >
                      <SVGIcon icon={'bw_insta'} height={'60px'}/>
                    </a>
                    <div style={{ width: '150px' }}/>
                  </div>
                </Footer>
              </Content>
              
        </AppGrid>  
      </ScrollToTop>
    </Router>  
  );
  function menuClick(event) {
    event.preventDefault();
    setanchor(event.currentTarget);
    openmenu(true);
  }
}

const NotFoundPage = () => (
  <div style={{textAlign: 'center'}}>
    <h1>404 - Page Not Found</h1>
    <p>We can't seem to find the page you're looking for.</p>
    <a href="/">Go back home</a>
  </div>
);

export default App;
