import Helmet from "react-helmet";
import React, { useEffect } from "react";
import img_product from "../src/images/productshot.svg";
import img_memory from "../src/images/Memory.png";
import img_brain from "../src/images/BrainAndHeart.png";
import {
  PointTitle,
  SquareButton,
  LeftGrid,
  CVPTitle,
  CarouseCard,
  RightGrid,
  Image,
  Attribute,
  CenterAlign
} from "./components";

import { trackpage } from "./trackpage";
import { Link } from "react-router-dom";
//import { abctest33 } from "./ABtesting";

/* const abbutton = abctest33(
  {title: "Find out more", link: "about"}, 
  {title: "Take the next step", link: "about"}, 
  {title: "Learn more", link: "about"}
);
 */
export default function How({setFilter}) {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  return (
    <CenterAlign>
      <Helmet>
        <title>How does SliceGoal work?</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.slicegoal.com/how-it-works/" />
        <meta
          property="og:title"
          content="How does SliceGoal work?"
        />
        <meta
          property="og:description"
          content="Revolutionary Memory, Transformative Instinct, and Groundbreaking Information."
        />
        <meta
          property="og:image"
          content="https://www.slicegoal.com/files/slicegoallong.png"
        />
      </Helmet>
      <div style={{gridArea: 'content'}}>
        <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
          How does SliceGoal work?
        </PointTitle>
        <LeftGrid>
          <CVPTitle style={{gridArea: 'left'}}>
            <>
              <CarouseCard style={{ marginTop: "50px", color: "#414144", fontWeight: 600, fontSize: '24px'}}>
                {"Revolutionary Memory"}
              </CarouseCard>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"You will see information on a cadence that helps you remember it. Without the struggle of forcing it."}
              </div>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"Your subconscious mind will recall information if it’s presented in a particular pattern."}
              </div>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"You will be a memory superstar."}
              </div>
            </>
          </CVPTitle>
          <div
            style={{
              gridArea: "right",
              display: 'flex',
              width: '200px',
              margin: 'auto'
            }}
          >
            <Image image={img_memory} id={'product'} alt={'Image of SliceGoal wheel.'} width={200} />
          </div>
        </LeftGrid>
        <RightGrid>
          <CVPTitle style={{gridArea: 'right'}}>
            <>
              <CarouseCard style={{ marginTop: "50px", color: "#414144", fontWeight: 600, fontSize: '24px'}}>
                {"Transformative Instinct"}
              </CarouseCard>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"We believe that your instinct is key."}
              </div>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"Offering recommendations to help you build your instinct for strength and growth."}
              </div>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"Everyone is different and will have their own unique path to strengthening their instinct."}
              </div>
            </>
          </CVPTitle>
          <div
            style={{
              gridArea: "left",
              display: 'flex',
              width: '300px',
              margin: 'auto'
            }}
          >
            <Image image={img_brain} id={'product'} alt={'Image of SliceGoal wheel.'} width={'200px'} />
          </div>
        </RightGrid>
        <LeftGrid>
          <CVPTitle style={{gridArea: 'left'}}>
            <>
              <CarouseCard style={{ marginTop: "50px", color: "#414144", fontWeight: 600, fontSize: '24px'}}>
                {"Groundbreaking Information"}
              </CarouseCard>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"You can now take advantage of the latest technology to connect information in a way that was not previously possible."}
              </div>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {"Your information is organised in a way that allows advanced management: linking, finding, and modifying information."}
              </div>
            </>
          </CVPTitle>
          <div
            style={{
              gridArea: "right",
              display: 'flex',
              justifyContent: 'right',
              margin: '0 20px'
            }}
          >
            <Image image={img_product} id={'product'} alt={'Image of SliceGoal wheel.'} width={'350px'} />
          </div>
        </LeftGrid>
        <div style={{gridArea: 'tiles', margin: '0 20px' }}>
          <PointTitle style={{ marginBottom:'10px' }} align={'center'}>
            The Three Pillars
          </PointTitle>
          <Attribute style={{fontSize: '24px', textAlign: 'center'}}>Memory - Instinct - Information</Attribute>
          <div style={{ fontSize: "16px", margin: "20px auto", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'center', maxWidth: '600px'}}>
            { "The perfect combination of memory enhancement, instinct building, and information."}
          </div>
          <div style={{ fontSize: "16px", margin: "20px auto", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'center', maxWidth: '600px'}}>
            { "To get started, click below."}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}
        >
          <Link to={"/about/"}>
            <SquareButton
              float={"none"}
              margin={10}
              light={true}
            >
              About SliceGoal
            </SquareButton>
          </Link>
          <Link to={"/start-here"}>
          <SquareButton
            float={"none"}
            margin={10}
          >
            Explore Your Potential
          </SquareButton>
          </Link>
        </div>
      </div>
    </CenterAlign>
  );
}
