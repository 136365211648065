import { Helmet } from 'react-helmet'
import React, { useEffect } from "react";
import {
  PointTitle,
  Attribute,
  Tiles,
  PromotionCard,
  SquareButton,
  GutterResponsive
} from "./components";

import { trackpage } from "./trackpage";
import { Link } from "react-router-dom";
import { abctest33 } from "./ABtesting";

import img_breakthrough from "../src/images/Breakthrough2.jpg";

const abbutton = abctest33(
  {title: "Find out more", link: "/how-it-works/"}, 
  {title: "Take the next step", link: "/how-it-works/"}, 
  {title: "Learn more", link: "/how-it-works/"}
);

function About() {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  return (
    <GutterResponsive>
      <div style={{gridArea: 'content'}}>
        <Helmet>
          <title>We're about Breakthroughs</title>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="www.slicegoal.com/about/" />
          <meta
            property="og:title"
            content="What We Stand For"
          />
          <meta
            property="og:description"
            content="Designed for breakthroughs. We draw inspiration from the best techniques in coaching, memory, and breakthroughs. We have a philosophy of continuous improvement and we're obsessed about how we can help you achieve your life goals."
          />
          <meta
            property="og:image"
            content="https://www.slicegoal.com/files/slicegoallong.png"
          />
        </Helmet>
          <div style={{gridArea: 'tiles', margin: '0 20px' }}>
            <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
              We're ALL About Breakthroughs!
            </PointTitle>
            <div style={{ fontSize: "18px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'left'}}>
              { "We draw inspiration from the best techniques in coaching, memory, and breakthroughs. We have a philosophy of continuous improvement and we're obsessed about breakthroughs that can help you achieve your life goals."}
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img style={{maxWidth: '860px', width: '100%'}} src={img_breakthrough} id={'product'} alt={'Breakthrough at Ayers Rocks.'} />
          </div>
        <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
          What we're NOT about
        </PointTitle>
          <Tiles>
            {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
            <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
            <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
            <PromotionCard name={'Not about productivity'} text={'Productivity alone is not going to get you to the next level. We believe breakthroughs come from attention and evolution.'} imageheight={'90px'}/>
            <PromotionCard name={'Workflows are boring'} text={'While you can track actions and tasks, focusing too much on workflows and actions can distract from the big picture.'} imageheight={'110px'}/>
            <PromotionCard name={'Grasping is Useless'} text={'Do you find yourself grasping for the next thing? Harness your attention to maintain a stable focus and achieve difficult goals.'} imageheight={'110px'}/>
          </Tiles>
        <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
          Our Values
        </PointTitle>
          <Tiles>
            {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
            <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
            <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
            <PromotionCard name={'Grow.'} text={'Growth is how you strengthen what you already have to create more value in your life.'} imageheight={'90px'} icon={'wreath'}/>
            <PromotionCard name={'Learn.'} text={'Learning is how you find something new and put it into action to impact your life.'} imageheight={'110px'} icon={'wreath'}/>
            <PromotionCard name={'Evolve.'} text={'Evolution is how you transform and step into the next phase of your vision.'} imageheight={'110px'} icon={'wreath'}/>
          </Tiles>
          <div style={{gridArea: 'tiles', margin: '0 20px' }}>
            <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
              A Human Focus
            </PointTitle>
            <Attribute style={{fontSize: '24px',  textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>We find opportunities to leverage technology to restore and respect the human side of life. Technology that actually works for you.</Attribute>
          </div>
        <PointTitle align={'center'} style={{ marginBottom:'10px', textAlign: 'center' }}>
          How does it work?
        </PointTitle>
        <div style={{ fontSize: "20px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: "center" }}>Click below to find out.</div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}>
          <Link to={'/start-here'}>
            <SquareButton
              float={"none"}
              margin={10}
              light={true}
            >
              {"Get started"}
            </SquareButton>
          </Link>
          <Link to={abbutton.link}>
            <SquareButton
              float={"none"}
              margin={10}
            >
              {abbutton.title}
            </SquareButton>
          </Link>
        </div>
      </div>
    </GutterResponsive>
  );
}

export default About;
