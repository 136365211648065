import { Helmet } from "react-helmet"
import React, { useEffect, useState } from "react"
import {
  PointTitle,
  BodyDiv,
  SquareButton
} from "./components"
/* import { Link } from "react-router-dom"; */

import { WheelCarousel } from "./wheel"
import { trackpage } from "./trackpage"
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core"

export default function StartHere({tagfilter}) {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  const [filter, settag] = useState(tagfilter);
  const [searchterm, setSearch] = useState('');

  return (
    <BodyDiv>
      <Helmet>
        <title>Choose Your Growth Areas</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="www.slicegoal.com/start-here" />
        <meta
          property="og:title"
          content="Choose Your Growth Areas"
        />
        <meta
          property="og:description"
          content="Start here. Decide the areas you will learn, grow, and evolve."
        />
        <meta
          property="og:image"
          content="https://www.slicegoal.com/files/slicegoallong.png"
        />
      </Helmet>
      <div style={{textAlign: "center", margin:'auto', marginTop: '60px'}}>
        <PointTitle style={{textAlign: "center"}}>
          Choose Your Growth Areas
        </PointTitle>
        <div style={{color: "#9499A3", margin: '10px 20px'}}>
          Choose the areas you want to learn, grow, and evolve.
        </div>
        <div style={{display: 'flex', margin: '10px 20px', height: '30px', justifyContent: 'center'}}>
          <TextField
              data-cy="optin-email"
              id="optin-email"
              //label="Search"
              placeholder="Search"
              margin="none"
              size="small"
              autoFocus={(window.innerWidth > 0 ? window.innerWidth : window.screen.width) > 700}
              variant="outlined"
              style={{ width: '300px', marginTop: '1px', marginBottom: '10px' }}
              onChange={e => {
                setSearch(e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setSearch(e.target.value)
                  settag(searchterm)
                }
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginBottom: "40px"
            }}
          >
              <SquareButton 
                style={{height: '40px', borderRadius: '8px'}} 
                onClick={() => settag(searchterm)} 
                float={"right"}
              >
                {'Search'}
              </SquareButton>
          </div>
        </div>
        <div id={'filters'} style={{marginTop:'20px'}}>
          <SquareButton
          onClick={() => settag("")}
          float={"none"}
          //margin={10}
          light={true}//{filter !== ""}
          >
            All
          </SquareButton>
          <SquareButton
            onClick={() => settag("business")}
            float={"none"}
            margin={10}
            light={true}//{filter !== "business"}
          >
            Business
          </SquareButton>
          <SquareButton
            onClick={() => settag("life")}
            float={"none"}
            //margin={10}
            light={true}//{filter !== "life"}
          >
            Lifestyle
          </SquareButton>
        </div>
      </div>
      <WheelCarousel tag={filter} />
      <div style={{gridArea: 'tiles', margin: '0 20px' }}>
          <PointTitle style={{ marginBottom:'10px' }} align={'center'}>
            Not ready to get started yet?
          </PointTitle>
          <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'center'}}>
            { "Learn more about SliceGoal first."}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}
        >
          <Link to={"/about/"}>
            <SquareButton
              float={"none"}
              margin={10}
              light={true}
            >
              About SliceGoal
            </SquareButton>
          </Link>
          <Link to={"/how-it-works/"}>
          <SquareButton
            float={"none"}
            margin={10}
            light={true}
          >
            How it works
          </SquareButton>
          </Link>
        </div>
    </BodyDiv>
  );
}
