import React, { useEffect } from "react";
/* import {
  Body,
  PointTitle,
  Attribute,
  BlogContainer,
  CenterAlign,
  Header,
  ProductShot,
  SquareButton
} from "./components"; */
import { trackpage } from "./trackpage";
/* import { BrowserRouter as Routes, Route } from "react-router-dom";
import BlogCoach from "./Blog.Coaching"
import Blog2022 from "./Blog.2022"
import OfferHabits from "./Offer.Habits.Optin" */

function Blog() {
  useEffect(() => {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  }, []);
  return (
      <div>Blog Landing!</div>
  );
}

export default Blog;
