import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import {
  Body,
  GridCVP,
  HabitBuilderHeroImage,
  Section,
  BodyDiv,
  SquareButton
} from "./components";
import { trackpage, sendUnsubscribeRequest } from "./trackpage";
import TextField from '@material-ui/core/TextField'

//import { abtest50, abctest33 } from "./ABtesting";
import { validateemail } from "./functions";
//const abtitle = '5 Step Guide to Build Habits Quickly\n\n Without the Struggle' //abtest50("Build Your Habits in 5 simple steps. Guaranteed.", '5 Step Guide to Build Habits Quickly\n\n Without the Struggle');
//const abbutton = abctest33("SEND MY FREE COPY NOW", "Claim Your FREE Copy Now", "Download Now");


function UnsubscribePage() {
  let navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setemail(params.get('email'))

    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    })
  },[])

  function callback(response) {
    if(response){
      toast.success(response, {duration: 4000})
      navigate("../offer/building-habits/success?email=" + email)
    } else {
      toast.error("Hmmm, something went wrong. Please try again.")
    }
  }

  const [email, setemail] = useState("")
  const [reason, setreason] = useState("")
  return (
    <BodyDiv style={{ textAlign: "center" }}>

        <GridCVP>
        <div
          style={{
            gridArea: "leftcvp"
          }}
        >
          <HabitBuilderHeroImage />
        </div>
        <div
          style={{
            gridArea: "rightcvp",
            display: "flex",
            alignItems: "center",
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Section>
            <Body style={{fontSize: '15px', marginBottom: '0px'}}>
              {
                "We're sorry to see you go. \
                Please tell us why you'd like to unsubscribe. \n\
                \n"
              }
            </Body>
            <TextField
                data-cy="optin-email"
                id="optin-email"
                label="email"
                value={email}
                margin="none"
                size="small"
                variant="outlined"
                style={{ width: '100%', marginBottom: '10px' }}
                onChange={e => {
                  setemail(e.target.value)
              }}
            />
            <TextField
                data-cy="optin-name"
                id="optin-name"
                label="reason for unsubscribing"
                margin="none"
                multiline
                autoFocus
                variant="outlined"
                size="small"
                style={{ width: '100%', marginBottom: '5px' }}
                onChange={e => {
                    setreason(e.target.value)
                }}
            />
            <div
              style={{
                textAlign: "center",
                marginBottom: "30px"
              }}
            >
                <SquareButton onClick={() => {
                    if (!validateemail(email))
                      toast.error("hmmm, we can't make sense of your email. Please update it and retry.")
                    else {
                      sendUnsubscribeRequest( email, reason, callback )
                    }
                  }} float={"right"}>
                  {'unsubscribe'}
                </SquareButton>
            </div>
          </Section>
        </div>
      </GridCVP>
      </BodyDiv>
  );
}

export default UnsubscribePage;
